<template>
    
    <div>
         <div class="h4 text-white font-weight-bold text-center my-3">
                <div class="welfares-header family">福利中心</div>
                 <div class="welfares-main family">
              <button @click="wel">援之以手</button>
              <button  @click="curbox">福利宝箱</button>
              <button style="border:1px solid #9FDA35; color:#9FDA35">会员福利</button>
              <button  @click="wel">qq福利群</button>
            </div>
                
            </div>
        <div class="content py-3 mt-3">
         
            <div class="h3 text-white font-weight-bold text-center position-relative">
                 <div class="myGrade">我的等级</div><!--{{ $route.meta.title }} -->
            </div>
            <div class="content-box pb-5">
                <div class="d-flex vip m-auto flex-nowrap content-box-table flex-wrap">
                    <div>
                        <div class="vip-title">VIP奖励等级</div>
                        <div class="vip-title">充值金额</div>
                        <div class="vip-title">升级红包</div>
                        <div class="vip-title border-gray-VIP">充值加成</div>
                        <div class="vip-title">红包领取</div>
                    </div>
                    <div id="content-scroll" class="content-box-scroll">
                        <div class="d-flex">
                            <div class="d-flex">
                                <div class="vip-con">
                                    <img style="height: 20px" :src="require('@/assets/img/nav/wan_vip' + (0) + '.png')" alt="">
                                </div>
                            </div>
                            <div v-for="(item, index) in VipList" :key="index"  class="d-flex" >
                                <div class="vip-con" :class="index + 1 === $store.state.user_info.vip_level ? 'bg-bg' : ''">
                                    <img style="height: 20px" :src="require('@/assets/img/nav/wan_vip' + (item.level) + '.png')" alt="">
                                </div>
                            </div>
                        </div>
                        <div class="d-flex">
                            <div class="d-flex">
                                <div class="vip-con">
                                    /
                                </div>
                            </div>
                            <div v-for="(item, index) in VipList" :key="index"  class="d-flex">
                                <div class="vip-con" :class="index + 1 === $store.state.user_info.vip_level ? 'bg-bg' : ''">
                                   {{ item.threshold }}
                                </div>
                            </div>
                        </div>
                        <div class="d-none">
                            <div class="d-flex">
                                <div class="vip-con">
                                    /
                                </div>
                            </div>
                            <div class="w-100 d-flex vip-silde d-flex align-items-center pl-2">
                            </div>
                        </div>
                        <div class="d-flex">
                            <div class="d-flex">
                                <div class="vip-con" >
                                    /
                                </div>
                            </div>
                            <div v-for="(item, index) in VipList" :key="index"  class="d-flex">
                                <div class="vip-con" :class="index + 1 === $store.state.user_info.vip_level ? 'bg-bg' : ''">
                                    <money></money>
                                    {{ item.packet }}
                                </div>
                            </div>
                        </div>
                        <div class="d-flex">
                            <div class="d-flex">
                                <div class="vip-con border-gray-VIP">
                                    /
                                </div>
                            </div>
                            <div v-for="(item, index) in VipList" :key="index"  class="d-flex" :class="index + 1 === $store.state.user_info.vip_level ? 'bg-bg' : ''">
                                <div class="vip-con" :class="index | borderColor" >
                                    {{ item.rebate }}%
                                </div>
                            </div>
                        </div>
                        <div class="d-flex souyouhongbao">
                            <div class="w-100">
                                <div class="vip-con-btn">
                                    <div class=" w-100">
                                      所有充值红包系统自动发放，请在收支明细中查看
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="highlighted-message text-center ">
                <div class="highlighted-message-body">
                    您距离升级{{ Viptips.next_level }} 还需充值 <money /> {{ Viptips.next_lack }}.将享受 {{ Viptips.next_rebate }} % 充值加成。
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import $api from '@/api/vip/index'
import Money from '../../common/money/money.vue';
  export default {
    name: "index",
    components: {Money},
    data() {
      return {
          VipList:[],
          Viptips:{},
      }
    },
    filters:{
      borderColor(index){
        if(index<=1){
          return 'border-gray-VIP';
        }
        if(index<=4){
          return 'border-blue-VIP'
        }
        if(index<=7){
          return 'border-purple-VIP'
        }
        if(index == 8){
          return 'border-red-VIP'
        }
        if(index == 9){
          return 'border-gold-VIP'
        }
      }
    },
    computed: {

    },
    created() {

    },
    async mounted() {
        await this.getVipList()


    },
    methods: {
        //导航按钮
        wel() {
            this.$router.push({ name: "center" });
        },
          curbox() {
            this.$router.push({ name: "outOfTheFree" });
        },
        getVipList(){
            $api.getVipList().then(res =>{
                this.VipList = res.data.list
                this.Viptips = res.data.tips
                document.getElementById('content-scroll').scrollLeft = this.$store.state.user_info.vip_level * 100
            })
        }
    }
  }
</script>

<style scoped lang="scss">
.myGrade{
    font-size: 26px;
}
.welfares-header {
  width: 310px;
  height: 71px;
  margin: 25px auto;
  background: url('../../assets/image/12.png');
  background-size: 100% 100%;
  // background: linear-gradient(146deg, #009FFF 0%, #009FFF 0%, #05377B 100%);
  // box-shadow: inset 1px 1px 0px 0px rgba(255, 255, 255, 0.5), 2px 5px 8px 0px #000000;
  opacity: 1;
  border-radius: 20px;
  text-align: center;
  line-height: 71px;
  font-size: 30px;
  font-weight: 400;
  color: #FFFFFF;
  -webkit-text-stroke: 1px black;
}
.welfares-main {
  width: 280px;
  height: 48px;
  margin: auto;
  display: flex;
  justify-content: space-between;
margin-bottom:20px;

  button {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    border: 1px solid RGBA(0, 159, 255, 1);
    font-size: 16px;
    color: RGBA(0, 159, 255, 1);
    font-weight: 400;
  }
}
    .souyouhongbao{
        width: 100%;
        @media (max-width: 1200px) {
            width: 1100px;
        }
    }
  .desc{
    width: 80%;
    margin:50px auto;
    @media (max-width: 1200px) {
      justify-content: flex-start;
      width: 90%;
      font-size: 12px !important;
      line-height: 1.3;
    }
    & .item{
      margin-bottom: 50px;
      & .tilte{
        margin-bottom: 20px;
        font-size: 17px;
        color: rgb(255, 255, 255);
      }
      & .info{
        color: rgb(181, 187, 194);
        font-size: 17px;
      }
    }
  }
    .vip {
        &-title {
            color: #c2c1cf;
            background-color: #28283b;
            border-bottom: 1px solid #222144;
            width: 147px;
            height: 70px;
            display: flex;
            justify-content: center;
            align-items: center;
            @media (max-width: 1200px) {
                width: 100px;
            }
        }
        &-silde{
            background-color: #2e2b5b;
            border-right: 1px solid #222144;
            border-bottom: 1px solid #222144
        }
        &-con {
            width: 100px;
            height: 70px;
            padding: 0 10px;
            font-size: 14px;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            /*background-color: #30303f;*/
            color: #d4d3dd;
            border-right: 1px solid #222144;
            border-bottom: 1px solid #222144;
            &-btn{
                font-size: 14px;
                text-align: center;
                line-height: 68px;

                background-color: #30303f;
            }
            &-award {
                width: 80%;
                height: 60%;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #333160;
            }

            & .active {
                background-color: red;
                color: #ffffff;
            }
        }
    }

    .grade {
        @media (max-width: 1200px) {
            font-size: 12px;
        }
    }

    .input-code {
        @media (max-width: 1200px) {
            width: 150px !important;
        }
    }

    .tong-height {
        height: 38px;
        line-height: 38px;
    }

    .side-box {
        width: 204px;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, .5)
    }

    .content {
        width: 90%;
        margin: 0 auto;
        background-color: #191926;
        @media (max-width: 1200px) {
            width: 96%;
            font-size: 12px !important;
        }

        &-box {
            &-scroll{
                background-color: #30303f;
                overflow-x: scroll;
                &::-webkit-scrollbar{
                    height: 2px !important;
                }
            }
            &-table {
              justify-content: center;
                @media (max-width: 1200px) {
                    justify-content: flex-start;

                    font-size: 12px !important;
                }
            }
        }
    }

    .cell {
        padding: 20px;

        @media (max-width: 1200px) {
            padding: 5px;
        }
    }

    .highlighted-message {
        width: 83%;
        margin: 10px auto;
        padding: 19px 5px 19px 16px;
        border: 1px solid #fada62;
        border-radius: 8px;
        background-color: rgba(250, 218, 98, 0.12);
        @media (max-width: 1200px) {
            width: 100%;
        }
    }


  .border-gray-VIP{
      border-bottom: 3px solid #d2d6ff;
  }
  .border-blue-VIP{
      border-bottom: 3px solid #5968ff;
  }
  .border-purple-VIP{
      border-bottom: 3px solid #9e6dff;
  }
  .border-red-VIP{
      border-bottom: 3px solid #af416e;
  }
  .border-gold-VIP{
      border-bottom: 3px solid #ffba6b;
  }


</style>
