
import {request} from "../index";

function getVipList(){
  return request({
    url: `/vip/lists`,
    method:'get'
  })
}


export default {
  getVipList
}
